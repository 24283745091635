
/* Settings */

.remove-context {
  display:none !important;
}

.drag-container {
  z-index:10000;
   position: absolute;
    margin:auto;
    left: 0;
    right: 0;
    top:0;
    bottom:0;
    width:500px;
    height:780px;
}

#settings-div {
    background-color: white;
    width:500px;
    height:780px;
    position: absolute;
    margin:auto;
    left: 0;
    right: 0;
    top:0;
    bottom:0;
    z-index:100000000000;
    box-shadow:0 1px 4px rgba(0,0,0,0.16), 0 1px 4px rgba(0,0,0,0.23);
    border-radius:4px;
}

#settings-div .simplebar-settings {
  height:calc( 100% - 26px )
}
    
#settings-div .settings-content {
    width:100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    font-size:12px;
    }

     #settings-div .settings-div-name {
      background-color: #2a3042;
      color:white;
      width:100%;
      text-align: center;
      font-size: 12px;
      font-weight: 500;
      padding:4px 4px;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
    }

    #settings-div .settings-name {
        border-bottom: 1px solid #dadadb;
        font-weight: 500;
        margin-bottom:20px;
    }

    #settings-div .settings-groups {
        display:flex;
        flex-direction: column;
        width:90%;
        margin-top:10px;
        margin-bottom:10px;
       
    }

    #settings-div .location-div {
      display:flex;
      justify-content: space-between;
      align-items: center;
      width:220px;
      margin-top:15px
    }

    #settings-div .location-div-first {
      display:flex;
      justify-content: space-between;
      align-items: center;
      width:220px;
    }


    #zone-arrow-1 {
      width:18px;
      height:22px;
      margin-right:5px;
      transform: rotate(0deg);
    }
    #zone-arrow-2 {
      width:18px;
      height:22px;
      margin-right:1px;
      transform: rotate(90deg);
    }

    #zone-arrow-3 {
      width:18px;
      height:22px;
      margin-right:5px;
      transform: rotate(180deg);
    }

    #zone-arrow-4 {
      width:15px;
      height:15px;
      margin-right:5px;
    } 



    #settings-div .label-container {
        display: flex;
        justify-content: space-between;    
    }

    #settings-div .input-container {
      display:flex;
      align-items: center;
      font-weight: 400;
    }

    #settings-div .input-element {
        margin-right:5px;    
    }
  
    #settings-div .settings-img {
        margin-right:5px;
    }

    #settings-div .textarea {
       height:70px;
       resize:none;
    }
     

 

    #settings-div .align-button {
        width: 30px;
        height:24px;
        border-radius: 3px;
        background: none;
        display: flex;
        justify-content: center;  
        align-items: center;
        box-sizing: border-box;
        font-size:16px;
        color: #a6a6a6;
        cursor: pointer;
        outline: none;
        border: none;
     }
     
     #settings-div .selected-align-button {
      border: 1px solid var(--selected-blue);
     }

     #settings-div .color-input {
     height:100%;
     width:40px;
     background-color:unset;
 }
 

#edittext {
    display:flex;
    width:100%;
    justify-content: space-between;
    height:23px;
}

.hinweis-text {
  font-size:10px;
  margin:auto;
}


#settings-div .button-container {
    margin:auto;
    display:flex;
    width:70%;
    justify-content: space-around;
  } 



#settings-div .select-text-mini {
  width:145px
}

#settings-div .select-text {
  width:170px
}
    



#letzteBenutzeraktivitaeten td{
  text-align: left ; 
  width: 33.33%;
  position: relative;
  border: 1px solid silver;
  padding:5px;
}

#letzteBenutzeraktivitaeten {
   height:100%;
   margin: auto;
   width:100%
}

#letzteBenutzeraktivitaeten th{
  text-align: left ; 
  padding:5px;
  font-weight: 400;
  border: 1px solid silver;
  width: 33.33%;
} 

#letzteBenutzeraktivitaeten table {
   word-wrap:normal;
   white-space: normal;
   border-spacing:2px;
   position: relative;
   width:100%;
}


#barrierTable td{
  text-align: left ; 
  position: relative;
  border: 1px solid silver;
  padding:5px;
}

#barrierTable {
   height:100%;
   margin: auto;
   width:100%
}

#barrierTable th{
  text-align: left ; 
  padding:5px;
  font-weight: 400;
  border: 1px solid silver;
} 

#barrierTable table {
   word-wrap:normal;
   white-space: normal;
   border-spacing:2px;
   position: relative;
   width:100%;
}



.td-cam{ 
	padding-top: 5px; 
	border: unset; 
     text-align: left ;
    font-size:13px ;
    width: 150px;
    display: table-cell;
vertical-align: top;
padding-left:20px;
 }



/* Time-Control */

.container-time {
  padding-top:20px;
  padding-bottom:20px;
    margin:auto;
      width: 90%;
      position: relative;
      height:auto;
  }
  
  .schedule {
      position: relative;
      z-index:100000;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
  }

  .time-control-modus {
    width:50%;
    height:25px;
  }



  
  #days-wrapper {
      width: 100%;
      background: transparent;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      position: relative;
  }
  
  
  #days-wrapper .day {
      display: flex;
      flex-direction: row;
      margin: 1px;
      background: transparent;
      width: 100%;
  }
  
  #days-wrapper .hour {
      background: #dddddd;
      margin-bottom: 2px;
      width: 30px;
      height: 30px;
      user-select: none;
  }
  
  #days-wrapper .hover {
      background: black;
  }

  #days-wrapper .hover-automatic {
    background:#f37f2d !important;
  }

  #days-wrapper .hover-free {
    background:#009c4c !important;
  }

  #days-wrapper .hover-occupied {
    background:#ef1826 !important;
  }

  #days-wrapper .hover-delete {
    background: #dddddd !important;
  }

  #days-wrapper .hover-text {
    background: purple !important;
  }
  
  
  #days-wrapper .disabled {
      pointer-events: none;
      opacity: 1;
      box-shadow: none;
  }
  
  #days-wrapper .selected {
      
  }

  
  #days-wrapper .tc-automatic {
    background:#f37f2d ;
  }

  #days-wrapper .tc-free {
    background:#009c4c ;
  }

  #days-wrapper .tc-occupied {
    background:#ef1826 ;
  }

  #days-wrapper .tc-text {
    background:purple ;
  }

  .x-icon {
    width:22px;
    height:22px;
    position:absolute;
    right:-4px;
    top:-4px;
    cursor:pointer;
    
  }
  
  .time-control-text {
    padding:10px 5px 5px 5px ;
    width:100%;
    background-color: white;
    height:150px;
    position: absolute;
    margin:auto;
    left: 0;
    right: 0;
    top:0;
    bottom:0;
    z-index:100004;
    box-shadow:0 1px 4px rgba(0,0,0,0.16), 0 1px 4px rgba(0,0,0,0.23);
    border-radius:4px;
    display:none;
  }

  #days-wrapper .hour:nth-child(odd)::after {
      content:"";
      position: absolute;
      margin-right: 0px;
      margin-top: 0px;
      width: 1px;
      height: 30px;
      background:white;
      user-select: none;
    }
  
    #days-wrapper .hour:nth-child(even)::after {
      content:"";
      position: absolute;
      margin-right: 0px;
      margin-top: 15px;
      width: 1px;
      height: 15px;
      background:white;
      user-select: none;
    }
  
  
  
    #days-wrapper .hour-firstrow::before {
      position: absolute;
      margin-left: -5px;
      margin-top: -16px;
      width: 0.01em;
      height: 20px;
      background:none;
      user-select: none;
      font-size:10px;
  }
  
   /* hours top */
  
  #days-wrapper .hour-firstrow:nth-child(1)::before {
      content:"00";
    }
  
    .hour-firstrow:nth-child(5)::before {
      content:"02";
    }
  
    .hour-firstrow:nth-child(9)::before {
      content:"04";
    }
  
    .hour-firstrow:nth-child(13)::before {
      content:"06";
    }
  
    .hour-firstrow:nth-child(17)::before {
      content:"08";
    }
  
    .hour-firstrow:nth-child(21)::before {
      content:"10";
    }
  
    .hour-firstrow:nth-child(25)::before {
      content:"12";
    }
  
    .hour-firstrow:nth-child(29)::before {
      content:"14";
    }
  
    .hour-firstrow:nth-child(33)::before {
      content:"16";
    }
  
    .hour-firstrow:nth-child(37)::before {
      content:"18";
    }
  
    .hour-firstrow:nth-child(41)::before {
      content:"20";
    }
  
    .hour-firstrow:nth-child(45)::before {
      content:"22";
    }
  
    .hour-firstrow:nth-child(48)::before {
      content:"24";
      margin-left: 3px !important;
    }
  
    
    #days-wrapper .hour-lastrow::before {
      position: absolute;
      margin-left: -5px;
      margin-top: 32px;
      width: 0.01em;
      height: 20px;
      background:none;
      user-select: none;
      font-size:10px;
  }
  
   /* hours bottom */
  
  #days-wrapper .hour-lastrow:nth-child(1)::before {
      content:"00";
    }
  
    #days-wrapper .hour-lastrow:nth-child(5)::before {
      content:"02";
    }
  
    #days-wrapper .hour-lastrow:nth-child(9)::before {
      content:"04";
    }
  
    .hour-lastrow:nth-child(13)::before {
      content:"06";
    }
  
    .hour-lastrow:nth-child(17)::before {
      content:"08";
    }
  
    .hour-lastrow:nth-child(21)::before {
      content:"10";
    }
  
    .hour-lastrow:nth-child(25)::before {
      content:"12";
    }
  
    .hour-lastrow:nth-child(29)::before {
      content:"14";
    }
  
    .hour-lastrow:nth-child(33)::before {
      content:"16";
    }
  
    .hour-lastrow:nth-child(37)::before {
      content:"18";
    }
  
    .hour-lastrow:nth-child(41)::before {
      content:"20";
    }
  
    .hour-lastrow:nth-child(45)::before {
      content:"22";
    }
  
    .hour-lastrow:nth-child(48)::before {
      content:"24";
      margin-left: 3px !important;
    }
  
  
    #days-wrapper .day::after {
      position: absolute;
      margin-left: -20px;
      margin-top: 7px;
      width: 0.01em;
      font-size:10px;
      font-weight: bold;
    }
  
     /* gr language for days */
  
    .day:nth-child(1):lang(gr)::after {
      content:"Mo";
    }
  
    .day:nth-child(2):lang(gr)::after {
      content:"Di";
    }
  
    .day:nth-child(3):lang(gr)::after {
      content:"Mi";
    }
  
    .day:nth-child(4):lang(gr)::after {
      content:"Do";
    }
  
    .day:nth-child(5):lang(gr)::after {
      content:"Fr";
    }
  
    .day:nth-child(6):lang(gr)::after {
      content:"Sa";
    }
  
    .day:nth-child(7):lang(gr)::after {
      content:"So";
    }
  
    /* eng language for days */
  
    .day:nth-child(1):lang(eng)::after {
      content:"Mo";
    }
  
    .day:nth-child(2):lang(eng)::after {
      content:"Tu";
    }
  
    .day:nth-child(3):lang(eng)::after {
      content:"We";
    }
  
    .day:nth-child(4):lang(eng)::after {
      content:"Th";
    }
  
    .day:nth-child(5):lang(eng)::after {
      content:"Fr";
    }
  
    .day:nth-child(6):lang(eng)::after {
      content:"Sa";
    }
  
    .day:nth-child(7):lang(eng)::after {
      content:"Su";
    }
  
  







    #hoverTimeControl {
      width:150px;
      height:100px;
      background-color: white;
      position:absolute;
      z-index:100000;
      display:none;
      box-shadow:0 1px 4px rgba(0,0,0,0.16), 0 1px 4px rgba(0,0,0,0.23);
      border-radius:4px;
    }

    .select-switch {
      display:flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size:11px;
  
  }





  #settings-text nav {
    position: relative;
    z-index: 1;
    padding-left: 1em;
  }

  #settings-text nav > a {
    position: relative;
    display: inline-block;
    padding: .3em 1em 0;
    color: inherit;
    text-decoration: none;
    margin: 0 -.3em;
  }

  #settings-text nav > a#tab3 {
    display: none;
  }

  #settings-text nav a::before {
    content: ''; 
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: -1;
    border-radius: .5em .5em 0 0;
    background: white ;
    box-shadow: 0 .15em white ;
    transform: scale(1.1, 1.3) perspective(.5em) rotateX(5deg);
    transform-origin: bottom;
    border:.1em solid #dadadb;
    margin-bottom: -0.8px;
  }

  @supports (-moz-transform: translate(0, 0)) {
    #settings-text nav a::before {
      margin-bottom:-1.3px ;
    }
}

  #settings-text nav .selected-tab{ z-index: 2;}

  #settings-text .tab {
    cursor:pointer;
  }

  #settings-text nav .selected-tab::before {
    background-color: white;
    box-shadow: 0 .15em white ;
    border-bottom: none;
  }

  #settings-text main {
    display: block;
    margin-bottom: 1em;
    background: white;
    padding: 1em;
    border-radius: 3px;
    border: .1em solid #dadadb;
  }






  @media only screen and (min-width:768px) and (max-width : 1024px) and (orientation : portrait),  
  only screen and (min-width:1024px) and (max-width : 1366px) and (orientation : landscape)
{

  #settings-div {

    height:75vh;
   
    }

}


@media only screen and (max-width : 1023px) and (orientation : landscape)  {
  #settings-div {
    width:60vw;
    height:90vh;
    z-index:1000;
    }

}



