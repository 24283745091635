
 @import "./switch.css";
 @import "./mapfs.css";
 @import "./context.css";
 @import "./settings.css";


.bodyoverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    z-index:99;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
}

 /* Legend */

.legend-div {
background-color: white;
width:450px;
height:710px;
position: absolute;
margin:auto;
left: 0;
right: 0;
top:0;
bottom:0;
z-index:10001;
box-shadow:0 1px 4px rgba(0,0,0,0.16), 0 1px 4px rgba(0,0,0,0.23);
border-radius:4px;
}

.simplebar-legend {
 height:calc( 100% - 30px );
}

.legendname {
    background-color: #2a3042;
    color:white;
    width:100%;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    padding:4px 4px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}

.legendinfo {
    text-align: center;
    width:100%;
    padding:5px 5px;
    font-size: 12px;
    margin-top:10px;
    margin-bottom:5px;
    font-weight:500;
}

.legendflex {
    display:flex;
    align-items: center;
    
}

.legendCarSVG {
 height:40px;
 width:50px;

}

.cars {
    display:flex;
    width:90%;
    margin:auto;
    font-size:12px;
}

.legendborder {
    border-top:1px solid #e3e3e9;
    padding-top:15px;
    width:100%;
}

.cars1 {
    display:flex;
    flex-direction: column;
    width:50%
}

.cars2 {
    display:flex;
    flex-direction: column;
    width:50%;
    margin-left:5px;
}

.cartext {
    margin-bottom:3px;
    margin-left:1px;
}

.parkplacesname {
    width:40%;
    display: flex;
    flex-direction: column;
    margin: auto;
    font-size:12px;
    padding-bottom:10px;
}

.pp {
    margin-right:5px;
}

.color1pp {
    color:green;
    -webkit-text-stroke: 2px green;
}

.color2pp {
    color:red;
    -webkit-text-stroke: 2px red;
}



.parkplacesbckg {
    width:45%;
    display: flex;
    flex-direction: column;
    margin: auto;
    font-size:12px;
    padding-bottom:10px;
}

.bckg {
width:30px;
height:10px;
margin-right:5px;
}

.color1bckg {
 background-color: white;
opacity: 0;
}

.color2bckg {
background-color: blue;
opacity: 0.1;
}

.color3bckg {
background-color: magenta;
opacity: 0.3;
}
   
.color4bckg {
background-color: yellow;
opacity: 0.3;
}

.color5bckg {
background-color: aqua;
opacity: 0.3;
 }
   

 .legendareatable {
    width:80%;
    display: flex;
    flex-direction: column;
    margin: auto;
    padding-bottom:10px;
}

.legendareatext {
    margin-left:10px;
    font-weight: normal;
    font-size:12px;
}

.legendareacont {
    margin-top:8px;
    display:flex;
    font-weight: bold;
    font-size: 11px;
}


 /* First Row */

 #firstrow {
    width:100%;
    top:65px;
    left:0px;
    position:relative;
    font-size:13px;
    padding-right:10px;
    padding-left:10px;
    display:flex;
    justify-content: space-between;
}

#legend { 
    cursor:pointer  
}

#areasTablet, #treeTablet, #selectmodus {
    display:none;
}

/* Fullscreen buttons*/

  #mapfs {
    top:4px;
    right:4px;
    position: absolute;
}

 .fsicon{
    font-size: 20px;
    color: #2a3042 !important;
    cursor: pointer;
    margin-left:7px;
}

/* Park tree*/

#parktreeall {
    position: relative;
    min-width:212px;
    word-wrap: break-word;
    word-break: break-all;
    white-space: normal;
    box-shadow: 0 1px 2px rgba(0,0,0,0.22);
    border-radius:3px;
    background-color:white;
    z-index:100;
    padding-top:5px;
    padding-bottom:5px;
    -webkit-user-select: none;     
-moz-user-select: none; 
-ms-user-select: none; 
user-select: none; 
 }

 .simplebar-parktree {
     height:100%;
 }

  .dropdown-icon {
    margin-left:-18px;
    cursor:pointer;
}

.parkimg2 {
    width:25px;
    height:25px;
}

.parkimg1 {
    width:25px;
    height:25px;
    margin-left:3px;
}

#parkname {
	margin-left:20px;
    display:flex;
    align-items: center;
    cursor:pointer;
}


.park2 {
    margin-left:30px;
    margin-top:10px;
    display:flex;
    align-items: center;
    font-size: 12px;
    cursor:pointer;
    
}

.flextree {
    display:flex;
    align-items: center;
    padding-left:5px;
}

.park3 {
    margin-left:50px;
    margin-top:10px;
    display:flex;
    align-items: center;
    cursor:pointer;
    font-size: 12px;
}

.park4 {
    margin-left:70px;
    margin-top:10px;
    display:flex;
    align-items: center;
    cursor:pointer;
    font-size: 12px;
}

 /* SVG*/

#svgmap {
    flex-grow: 100;
    margin-left:10px;
    margin-right:10px;
    position: relative;
    border-radius:4px;
    background-color:white;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-collapse: separate;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: 0 1px 2px rgba(0,0,0,0.22);
}

.svg {
width:calc(100vw - 745px);
height: calc(100vh - 100px);

}

#hoverLots {
    display:none;
    min-width:205px;
    height:auto;
    position:absolute;
    background-color:white;
    z-index: 9999;
    margin:auto;
    box-shadow:0 1px 4px rgba(0,0,0,0.16), 0 1px 4px rgba(0,0,0,0.23);
    border-radius:4px;
    word-wrap: break-word;
    white-space: normal;
}

.svgtablewrapper {
    display:flex;
    flex-direction: column;
    width:100%;
    padding:10px 20px 10px 20px;
}


.tds{ 
	padding-top: 3px; 
	border: unset; 
     text-align: left ;
    font-size:10px ;
    width: 97px;
    display: table-cell;
vertical-align: top;
 }

 .tds2 { 
	padding-top: 3px; 
	border: unset; 
     text-align: left ;
    font-size:10px ;
    min-width: 97px;
    display: table-cell;
vertical-align: top;
padding-left:10px;
 }

 .breakwords {
    word-wrap: break-word;
    word-break: break-all;
    white-space: normal;
}

.nocar {
    opacity:0;
}

 /* Second table on hover */

 #showinfo {
     display:none;
     min-width:235px;
     height:auto;
     position:absolute;
     background-color:white;
     z-index:9999;
     box-shadow:0 1px 4px rgba(0,0,0,0.16), 0 1px 4px rgba(0,0,0,0.23);
     border-radius:4px;
     padding-bottom:15px;
 }

 .flexcontainer {
     display:flex;
    flex-direction:column;
    align-items:center;
 }

 .tablehovername {
    background-color: #2a3042;
    color:white;
    width:100%;
    text-align: center;
    font-size: 11px;
    font-weight: 500;
    padding:3px 3px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}

.tablehoverinfo {
    text-align: center;
    width:100%;
    padding-top:10px;
    padding-bottom:10px;
    font-size: 11px;
    font-weight:500;
   
}

.bordertable {
    margin-top:10px;
    border-top:1px solid #e3e3e9;
}

.table2 {
    font-size:10px;
    word-wrap: break-word;
    white-space: normal;
    padding-left:15px;
    padding-right:15px;
    width:100%;
}

.table2-container {
    width:100%;
    padding-left:15px;
    padding-right:15px;
}

.th2 { 
    font-weight: 400;
    width: 25px ;
    border:unset !important;
	}

.td2, .th2{ 
	padding: 1px; 
     border:unset !important;
	text-align: center ; 
	font-size: 10px;
    }


.td2 {
    width:25px ;
    font-size:10px ; 
}

.td2-first {
    width:40px ;
    font-size:10px ; 
}

.th2-first {
    width:40px ;
    text-align: unset;
}



 /* Third table on hover */


.table3-container {
    display:flex;
    width:100%;
    justify-content: space-around;
    padding-left:15px;
    padding-right:15px;
    word-wrap: break-word;
    white-space: normal;
    font-size:10px;
}


.td3{ 
	border: unset; 
    width:100px;
}

.td3-2 {
    text-align: center;
	border: unset; 
    width:75px;
}


 /* First table */


 .first {
    background:#f1f1f3;   
}

.second {
    background: white;   
}

.third {
    background: white;   
}

.table1 { 
    word-wrap: break-word;
    word-break:normal;
    white-space: normal;
    border-radius: 4px;
    width:345px;
    box-shadow: 0 1px 2px rgba(0,0,0,0.22);
    background-color:white;
    z-index:100;
    height:100%;
    -webkit-user-select: none;     
    -moz-user-select: none; 
    -ms-user-select: none; 
    user-select: none; 
}

.simplebar-areas {
    height:calc(100vh - 125px);
}


.thirdarea {
    padding-left:5px !important;
}

.areahead1, .arearow1 {
    min-width: 158px;
}

.arearow1{
    padding: 4px; 
	padding-left:20px;
    font-size: 12px;
}


.flexarea {
    display:flex;
    align-items: center;
}

.flexarea .leftarea {
    margin-left:2px;
}

.arearow1sub {
    padding: 4px; 
	padding-left:35px;
    font-size: 12px;  
}

.areahead1 {
	color: white; 
    font-weight: 500; 
    text-align: center; 
    font-size: 12px; 
    padding: 4px 4px; 
    border:unset !important;
    text-align: center !important; 
  
}

.th1 { 
	color: white; 
    font-weight: 500; 
    min-width: 46.5px;
    border:unset !important;
 }
    
 
.td1, .th1 { 
	padding: 4px 4px; 
	text-align: center !important; 
    font-size: 12px;
   
    }
    
.td1 {
    min-width: 46.5px;
}

.thead1 {
    background-color: #2a3042;
    border-top-right-radius: 4px ;
    border-top-left-radius: 4px ;
    display: block; 
}


/*Tablets*/   

@media only screen and (min-width:768px) and (max-width : 1024px) and (orientation : portrait),  
       only screen and (min-width:1024px) and (max-width : 1366px) and (orientation : landscape)
   {


    #parkcurrent {
        left:60px;  
        position: absolute;  
    }
    
    #legend {
        right:10px;   
        position: absolute;    
    }
    
    
    #treeTablet {
        position: absolute;
        top:0px;
        left:10px;  
        cursor:pointer; 
        display:block;
    }


#areasTablet {
    position: absolute;
    top:0px;
    right:90px;  
    cursor:pointer;
    display:block; 
}



  #selectmodus {
      display:flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size:12px;
      position: absolute;
      top:-5px;
      right:48%;  
  }



    #parktreeall {
        display:none;
        position: absolute;
        width:240px;
        top:60px;
        left:-400px; 
        padding-left:10px;
        padding-bottom:70px;
         height:100%;
         z-index:100;
         border-radius:unset;
     }

    .legend-div {
        height:600px;
        }
  
#mapfs {
    display:none;
}

     /* SVG*/

#svgmap {
    margin-left:unset;
    margin-right:unset;
    border:none;
    position: absolute;
    width:100%;
    top:100px;
    right:0px;
    left:0px;
    bottom:0px;
    background-color:unset;
    border-radius:unset;
    box-shadow:unset;
    height: calc(100vh - 100px);
}

.svg {
    width:100vw;
    height: 100vh;
}

.table1 { 
    display:none;
	width: 300px; 
    position: absolute;
    top:60px;
    right:-400px;
    border:unset;
}

.simplebar-areas {
    height:calc(100vh - 85px);
}

.thead1 {
    border-top-right-radius: unset;
    border-top-left-radius:  unset;
}

}





@media only screen and (max-width : 767px) and (orientation : portrait)  {
   

#legend {
    display:none;   
}


.table1 { 
	width: 100%; 
    position: absolute;
    top:60px;
    right:0;
    left:0;
    margin-left: 0px; 
    margin-right: auto; 
} 

.simplebar-areas {
    height:calc(100vh - 85px);
}

.thead1 {
    border-top-right-radius: unset;
    border-top-left-radius:  unset;
}

.td1, .th1 {
    min-width: 15vw;
    max-width: 15vw;
}

.areahead1, .arearow1 {
    min-width: 40vw;
    max-width: 40vw;
}


#areasTablet, #treeTablet, #selectmodus, #parkcurrent, #parktreeall, .legend-div, #svgmap, #mapfs  {
    display:none ;
}

#hoverLots, #contextmenu, #contextmenu2 {
    display:none !important;
}


}


@media only screen and (max-width : 1023px) and (orientation : landscape)  {

  
    #showinfo {
        display:none !important;
    }

    #mapfs, .table1, #mapfs, #areasTablet  {
        display:none;
    }


 #firstrow {
    top:0px;
    height:17px;
    z-index:98;
}

#parkcurrent {
    left:60px;  
    position: absolute;  
}

#legend {
    right:10px;   
    position: absolute;    
}


#treeTablet {
    position: absolute;
    top:0px;
    left:10px;  
    cursor:pointer; 
    display:block;
}


  #selectmodus {
    display:flex;
    flex-direction: unset;
    justify-content: center;
    align-items: unset;
    position: absolute;
    top:0px;
    right:90px;  
  }


    #parktreeall {
        display:none;
        position: absolute;
        width:220px;
        top:0px;
        left:-400px; 
        padding-left:10px;
        bottom:50px;
         height:100%;
         border-radius: unset;  
     }


    .legend-div {
     width:60vw;
     height:90vh;
     z-index:1000;
     }


     /* SVG*/

#svgmap {
    margin-left:unset;
    margin-right:unset;
    border:unset;
    position: absolute;
    width:100%;
    top:0px;
    right:0px;
    left:0px;
    bottom:0px;
    box-shadow: unset;
    margin-right:0px;
    margin-left:0px;
    padding-top:35px;
    height:100%;
}

.svg {
    width:100vw;
    height: 100vh;
}

}